











import { defineComponent, ref } from "@vue/composition-api";
import LoginInterface from "../components/LoginInterface.vue";
import VerifyEmailToast from "@/components/Toast/VerifyEmailToast.vue";
import ShowConfirm from "@/components/ShowConfirm.vue";

export default defineComponent({
    name: "Signup",
    components: { ShowConfirm, VerifyEmailToast, LoginInterface },
    props: {
        welcome: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        // const { isEmailVerified } = useAuth()
        // const { showToast } = useToast()
        let showConfirm = ref(false)

        const signupFinish = (): void => {
            showConfirm.value = true;
            // if (!props.welcome) setTimeout(() => {
            //     router.push('/login')
            // }, 2000)
        }

        // const checkVerify = async (): Promise<any> => {
        //     const response = await isEmailVerified()
        //     console.log(response)
        //
        //     if (!response?.data.emailVerified) return showToast('verify-email-toast')
        //
        //     if (props.welcome) return await router.push('/voucher')
        //     return await router.push('/license')
        // }

        return {
            showConfirm,
            signupFinish,
            // checkVerify
        }
    }
})
